.b-list-section {}

.b-list-section__title {
    margin-bottom: 120px;
    max-width: 593px;

    @include lg() {
        margin-bottom: 100px;
    }

    @include md() {
        margin-bottom: 80px;
    }

    @include sm() {
        margin-bottom: 60px;
    }

    @include xs() {
        margin-bottom: 40px;
    }
}

.b-list-section__content {}

.b-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    column-gap: 20px;

    @include child-counter('> li', '', '\a') {
        width: 80px;
        height: 80px;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 4px solid #E8F6FC;
        margin-bottom: 40px;

        @include md(){
            width: 60px;
            height: 60px;
        }

        @include xs(){
            margin-bottom: 20px;
            font-size: 16px;
        }
    }
}

.b-list__punkt {
    @include adaptive-font(28, 20);
    font-weight: 600;
    line-height: 1.4;
    max-width: 480px;
    flex: 1 1 30%;

    &:not(:nth-last-child(-n+3)) {
        margin-bottom: 80px;
    }

    @include md(){
        flex: 1 1 47%;
        max-width: 47%;

        &:not(:nth-last-child(-n+2)) {
            margin-bottom: 80px;
        }
    }

    @include xs(){
        max-width: 100%;
        flex: 1 1 100%;

        &:not(:nth-last-child(1)) {
            margin-bottom: 40px;
        }
    }
}