.b-tile {
    border: 4px solid #E8F6FC;
    border-radius: var(--radius);
    padding: get-vw(80px);
    display: flex;
    column-gap: 40px;
    transition: all .4s;

    &:hover{
        background-color: #fafafa;
        border-color: #fafafa;
    }

    @media(max-width: 900px){
        flex-direction: column;
    }

    @include xs(){
        padding: 25px;
    }

    @media(max-width: 360px){
        padding-top: 30px;
        padding-bottom: 30px;
    }

    @media(min-width: 1920px){
        padding: 80px;
    }
}

.b-tile__col {}

.b-tile__col_left {
    max-width: 390px;
    flex: 1 1 auto;
}

.b-tile__mark {
    margin-bottom: 20px;
}

.b-mark {}

.b-mark_unselect {}

.b-tile__title {
    line-height: 1.2;
    max-width: 90%;
    margin-bottom: 40px;
    text-align: initial;
    transition: all .4s;
    font-size: calc(21px + 20 * (100vw / 1920)) !important;
    @include md() {
        font-size: calc(28px + 20 * (100vw / 1920));
    }
}

.b-tile__text {
    font-weight: 400;
    transition: all .4s;

    @media(max-width: 900px){
        margin-bottom: 30px;
    }
}

.b-tile__col-right {}

.b-tile__icon {
    max-width: 200px;
    max-height: 200px;
    border-radius: var(--radius);
    margin-bottom: 34px;
    aspect-ratio: 1/1;
    @media(max-width: 360px){
        display: none;
    }
}

.b-tile__group,
.b-tile__working {
    font-size: 16px;
    font-weight: 500;
    transition: all .4s;
}

.b-tile__working {
    margin-bottom: 5px;
    @media(max-width: 360px){
        margin-bottom: 0;
    }
}