.b-callback {}

.b-callback__title {
    text-align: initial;
    margin-bottom: 80px;
    @include sm(){
        margin-bottom: 40px;
    }
}

.b-title {}

.b-title_lg {
    text-align: left;
}

.b-form, .wpcf7-form {
    .wpcf7-form-control-wrap {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        max-width: 500px;
    }
}

.b-form__wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    margin-bottom: 40px;

    @media(max-width: 620px){
        flex-direction: column;
        margin-bottom: 20px;
    }
}

.b-form__input {
    //flex: 1 1 100%;
    //max-width: 500px;
    width: 100%;
}
.b-form__input_name {
    //flex: 1 1 100%;
    //max-width: 500px;
    @media (max-width: 620px) {
        margin-bottom: 20px;
    }
}

.b-form__input_mail {
    flex: 1 1 100%;
    max-width: 500px;
}

.b-form__inner {
    margin-bottom: 80px;

    @media(max-width: 620px){
        margin-bottom: 40px;
    }
}

.b-form__input {
    border-radius: var(--radius);
    border: 4px solid #EEEEEE;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 500;
    padding: 25px 40px;
    font-weight: 500;
    outline: transparent;
    transition: all .2s;
    max-height: 80px;

    &::placeholder {
        color: rgba(#141B1F, 0.6);
    }

    &:focus {
        border-color: var(--accent-color);
    }

    &:not(:last-child) {
        @media(max-width: 620px){
            margin-bottom: 20px;
            min-width: 100%;
        }
    }
    
    @include mob(){
        padding: 15px 25px;
    }

    @media(max-width: 620px){
        min-width: 100%;
    }
}

.b-form__tetfield {
    border-radius: var(--radius);
    border: 4px solid #EEEEEE;
    font-size: 16px;
    line-height: 0.1 0.8;
    font-weight: 500;
    padding: 25px 40px;
    font-weight: 600;
    outline: transparent;
    transition: all .2s;
    width: 100%;
    max-height: 140px;
    font-weight: 500;

    &::placeholder {
        color: rgba(#141B1F, 0.6);
    }

    &:focus {
        border-color: var(--accent-color);
    }
}

.b-form__btn {
    position: relative;
    z-index: 1;
}

.b-form__bottom-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;

    @media(max-width: 620px){
        flex-direction: column;
        align-items: flex-start;
    }
}

.wpcf7-not-valid-tip {
    color: #ff5d5b !important;
    font-size: 16px !important;
    margin-left: 45px !important;
    margin-top: 7px !important;
}

.wpcf7-spinner {
    position: absolute !important;
    right: 0;
    bottom: 30px;
    background-color: #eab8b7 !important;
    z-index: 2;
    @include sm() {
        bottom: 20px;
    }
}

.invalid .wpcf7-response-output {
    border-color: #eab8b7 !important;
}

.wpcf7-response-output {
    padding: 15px 35px !important;
    font-size: 16px !important;
    border-radius: 40px !important;
}

.b-form__agr {
    max-width: 380px;
    font-weight: 400;
    font-size: 16px;
    @media(max-width: 620px){
        margin-bottom: 25px;
    }
}

.b-form__agr-link {
    font-weight: 600;
    position: relative;

    &:after {
        content: '';
        width: 0;
        height: 1px;
        background-color: #000;
        left: 0;
        bottom: 0;
        position: absolute;
        transition: all .3s;
        width: 100%;
    }

    &:hover:after {
        width: 0;
    }
    @include sm(){
        &::after {
            display: none;
        }
    }
}

.b-form__btn {}

.b-btn {}

.b-btn_xl {}

.b-btn_secondary {}

.b-btn_arrrow {}

.b-main__section_form-background {
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 30px;
    margin-right: 30px;
    background-position: top center;

    @include xl(){
        margin-right: 0;
        margin-left: 0;
        background-size: cover;
    }
    @media(max-width: 620px){
        background: none !important;
    }
}