.b-callback-btn {
  display: flex;
  width: 75px;
  height: 75px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  background: var(--accent-color);
  border-radius: 25px;
  box-shadow: 3px 3px 15px rgba(52, 60, 91, 0.25);
  &:hover {
    background: var(--dark-blue);
    box-shadow: 3px 3px 15px rgba(52, 60, 91, 0.35);
    cursor: pointer;
  }
  @include xs() {
    width: 50px;
    height: 50px;
    border-radius: 15px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
  svg {
    animation-name: rotate-phone;
    animation-delay: 5s;
    animation-duration: 6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

@keyframes rotate-phone {
  0% {
    transform: rotate(-15deg);
  }
  1% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}