.b-about-section {
    padding-top: 160px;
    padding-bottom: 70px;
    position: relative;
    @include xl(){
        padding-top: 140px;
    }
    @include lg(){
        padding-top: 120px;
        padding-bottom: 0;
    }
    @include md(){
        padding-top: 90px;
    }
    // @include sm(){
    //     padding-top: 60px;
    // }
}

.b-about-section__wrapper {
    padding: get-vw(80px);
    background-color: #fafafa;
    border-radius: var(--radius);
    display: flex;
}

.b-about-section__text-col {
    max-width: 640px;

    @include lg(){
        max-width: 550px;
        z-index: 3;
        position: relative;
    }
}

.b-about-section__title {
    margin-bottom: 80px;
    text-align: initial;
}

.b-about-section__description {
    max-width: 640px;
    margin-bottom: 80px;
    font-weight: 400;
    line-height: 1.6;
    @include adaptive-font(24,18);

    & p {
        margin-bottom: 10px;
    }
}

.b-about-section__text {}

.b-about-section__btn {}

.b-btn {}

.b-btn_xl {}

.b-btn_primary {}

.b-btn_arrow {}

.b-about-section__logo {
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% + get-vw(60px)));
    right: 0;
    width: get-vw(758px);
    height: get-vw(868px);
    @media(min-width: 1920px){
        width: 758px;
        height: 868px;   
    }
}

@media(max-width: 1026px){
    .b-about-section__logo {
        position: static;
        transform: none;
    }

    .b-about-section__wrapper {
        column-gap: 40px;
        align-items: center;
    }
    .b-about-section__description  {
        margin-bottom: 50px;
    }
}
@media(max-width: 826px){
 
    .b-about-section__wrapper {
        flex-direction: column-reverse;
        padding: 40px;
        position: relative;
    }
   .b-about-section__logo {
    margin-top: -100px;
    margin-bottom: 40px;
   }
   .b-about-section__title {
       margin-bottom: 45px;
   }
   .b-about-section {
    //    padding-top: 0 !important;
   }
   .b-about-section__title {
       text-align: center;
   }
}

@media(max-width: 460px) {
    .b-about-section__logo {
        margin-bottom: 10px;
    }
    .b-about-section__wrapper {
        width: 100vw;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 0;
    }
}