.b-article-content {
    &>*:not(h1, h2, h3, h4, h5, h6) {
        margin-bottom: 80px;

        @include md() {
            margin-bottom: 60px;
        }

        @include xs() {
            margin-bottom: 40px;
        }
    }
    img {
        margin-top: 80px;
        margin-bottom: 80px;

        @include md() {
            margin-top: 60px;
            margin-bottom: 60px;
        }

        @include xs() {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }




    & table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 80px;
        @include md() {
            margin-top: 60px;
        }

        @include xs() {
            margin-top: 40px;
        }

        & thead {
            font-size: 14px;
            font-weight: 600;


            & tr {
                border-bottom: 2px solid #141B1F;

                & td {
                    padding-bottom: 20px;
                }
            }
        }

        & tbody {
            font-size: 18px;
            font-weight: 400;

            @include xs() {
                font-size: 16px;
            }

            & tr {
                border-bottom: 2px solid #EEEEEE;

                & td {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }
        }
    }
}

.b-article-content {

    img,
    iframe,
    video {
        width: 100%;
        border-radius: var(--radius);

        @include sm() {
            border-radius: 20px;
        }
    }

    iframe {
        aspect-ratio: 16/9;
    }

    p,
    li {
        @include adaptive-font(24, 18);
        margin-bottom: 12px;
    }

    h1 {
        @include adaptive-font(64, 32);
        line-height: 1.2;
        margin-bottom: 20px;
    }

    h2 {
        @include adaptive-font(48, 28);
        line-height: 1.2;
        margin-bottom: 20px;
    }

    h3 {
        @include adaptive-font(36, 22);
        line-height: 1.2;
        margin-bottom: 20px;
    }

    h4 {
        @include adaptive-font(24, 18);
        line-height: 1.2;
        margin-bottom: 20px;
    }

    h5 {
        @include adaptive-font(18, 12);
        line-height: 1.2;
        margin-bottom: 20px;
    }

    h6 {
        @include adaptive-font(14, 10);
        line-height: 1.2;
        margin-bottom: 20px;
    }
}

.b-video-container {
    position: relative;
}

.b-video {
    position: relative;
}

.b-about-first-screen__video {}

.b-video__preview {}

.b-video__play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 200px;
    height: 200px;

    &:hover #play-arrow {
        fill: var(--accent-color);
    }

    @include sm() {
        width: 150px;
        height: 150px;
    }

    @include xs() {
        width: 100px;
        height: 100px;
    }
}

#play-arrow {
    transition: all .3s;
}


.b-video-play-btn {}