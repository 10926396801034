
//variables
:root {
    --accent-color: #47B4EB;
    --second-color: #EAB8B7;
    --dark-blue: #2F80ED;
    --bcg-color: #FAFAFA;
    --container-width: 1600px;
    --container-xl-width: 1840px;
    --container-lg-width: 1760px;
    --radius: 40px;
    --offset: 80px;
}

$maxWidth: 1920;

//breakpoints
//usage: @include md() {...}
$breakpoint-xl: 1440px;
$breakpoint-lg: 1170px;
$breakpoint-md: 960px;
$breakpoint-sm: 780px;
$breakpoint-xs: 560px;
$breakpoint-mobile: 420px;

@mixin xl() {
    @media (max-width: $breakpoint-xl) {
        @content;
    }
}

@mixin lg() {
    @media (max-width: $breakpoint-lg) {
        @content;
    }
}

@mixin md() {
    @media (max-width: $breakpoint-md) {
        @content;
    }
}

@mixin sm() {
    @media (max-width: $breakpoint-sm) {
        @content;
    }
}

@mixin xs() {
    @media (max-width: $breakpoint-xs) {
        @content;
    }
}

@mixin mob() {
    @media (max-width: $breakpoint-mobile) {
        @content;
    }
}



//PX to VW conversion
//usage: width: get-vw(320px);
@function get-vw($font) {
    $vw-context: calc($maxWidth * 0.01 * 1px);
    @return calc($font / $vw-context * 1vw);
    @return $font;
}



//adaptive font size
//usage: @include adaptive-font(64, 32)
@mixin adaptive-font($pcSize, $mobSize) {
    $addSize: $pcSize - $mobSize;
    $addMobSize: $addSize + $addSize * 0.7;

    @media (max-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
    }

    @media (min-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
    }
}


//list counter
//usage: @include child-counter('> li', '', '\a'){...}
@mixin child-counter($child-selector, $content-before:'', $content-after:'') {
    $counter: unique-id();
    list-style-type: none;
    counter-reset: $counter;

    & #{$child-selector} {
        counter-increment: $counter;

        &:before {
            content: '#{$content-before}'counter($counter) '#{$content-after}';
            @content;
        }
    }
}
