.b-slide-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 80px;
}

.swiper {}

.mySwiper {
   
}

.b-slide-section__slider {
    max-width: 760px;
    position: relative;
    padding-left: 80px;
    padding-bottom: 80px;
    margin-left: -80px;
    &:before {
        content: '';
        width: 100%;
        // height: 100%;
        position: absolute;
        max-width: 680px;
        top: 80px;
        right: 80px;
        left: 0;
        bottom: 0;
        background-color: #fafafa;
        border-radius: var(--radius);
    }
}

.swiper-wrapper {}

.swiper-slide {
    border-radius: var(--radius);
    position: relative;
    height: auto !important;
}

.swiper-slide__img {
    object-fit: cover;
    z-index: 2;
    position: relative;
    max-width: 680px;
    min-height: 100%;
    border-radius: var(--radius);
    cursor: grab;
}

.swiper-slide-active {
   
}

.swiper-button-next {}

.swiper-button-prev {}

.swiper-pagination {}

.b-slide-section__description {
    max-width: 760px;
}

.swiper {}

.swiper-arrows {
    position: absolute;
    right: 40px;
    bottom: 31px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    column-gap: 20px;
}

.swiper-button-next,
.swiper-button-prev {
    position: relative;
    height: 80px;
    width: 80px;
    background-color: #fff;
    border-radius: var(--radius);

    &:hover {
        opacity: .95;
    }
}

.swiper-button-prev {
    margin-right: 20px;

    &:after {
        background-image: url('../img/arrow-slider.svg');
        background-repeat: no-repeat;
        background-size: 24px 24px;
        transform: rotate(-180deg) scale(1.3) translateY(-1px);
        color: transparent;
        position: absolute;
        line-height: 0.5;
    }
}

.swiper-button-next {
    margin-right: 0;

    &:after {
        background-image: url('../img/arrow-slider.svg');
        background-repeat: no-repeat;
        background-size: 24px 24px;
        color: transparent;
        position: absolute;
        line-height: 0.5;
        transform: scale(1.3);
    }
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 1;

    &:after {
        opacity: .35;
    }
}

.swiper-pagination.swiper-pagination-custom {
    color: #fff;
    background-color: #141B1F;
    font-size: 18px;
    padding: 5px 20px;
    max-width: max-content;
    border-radius: 20px;
    position: absolute;
    left: 40px;
    bottom: 40px;
}

@include xl(){
    .b-slide-section {
        column-gap: 60px;
    }
    .b-slide-section__slider {
        max-width: 630px;
    }
    .swiper-slide__img {
        max-width: 550px;
    }
    .b-slide-section__slider {
        padding-left: 60px;
        padding-bottom: 60px;
        margin-left: -60px;
    }
    .b-slide-section__slide {
        max-width: 550px;
    }
    .b-slide-section__slider:before {
        max-width: 550px;
    }
}
@include lg(){
    .b-slide-section {
        column-gap: 40px;
    }
    .b-slide-section__slider {
        max-width: 520px;
    }
    .swiper-slide__img {
        max-width: 480px;
    }
    .b-slide-section__slider {
        padding-left: 40px;
        padding-bottom: 40px;
        margin-left: -40px;
    }
    .b-slide-section__slide {
        max-width: 480px;
    }
    .b-slide-section__slider:before {
        max-width: 480px;
    }
}
@include md(){
    .b-slide-section {
        column-gap: 30px;
    }
    .b-slide-section__slider {
        max-width: 480px;
    }
    .swiper-slide__img {
        max-width: 420px;
    }
    .b-slide-section__slider {
        padding-left: 30px;
        padding-bottom: 30px;
    }
    .b-slide-section__slide {
        max-width: 420px;
    }
    .b-slide-section__slider:before {
        max-width: 420px;
    }
    .b-title-box__title {
        margin-bottom: 40px;
    }
    .swiper-button {
        width: 60px;
        height: 60px;
    }
    .swiper-arrows {
        right: 50px;
    }
    .b-title-box__subtitle {
        margin-bottom: 40px;
    }
}

@media(max-width: 890px){

    .b-slide-section {
        max-width: 100vw;
        flex-direction: column;
        align-items: center;
    }
    .b-slide-section__slider {
        max-width: 100%;
        margin-left: 0;
    }
    .swiper-slide__img {
        min-width: 100%;
        max-width: 100%;
        border-radius: 0;
    }
    .b-slide-section__slider:before {
        display: none;
    }
    .b-slide-section__slider {
        padding-left: 0;
        padding-bottom: 0;
    }
    .b-slide-section__slider  .swiper {
        margin-bottom: 40px;
    }
    .b-title-box__subtitle{
        max-width: 100%;
    }
}

@media(max-width: 820px){
    .b-slide-section__description .b-title-box {
        padding-left: 25px;
        padding-right: 25px;
    }
}

@include mob(){
    .swiper-pagination.swiper-pagination-custom {
        left: 20px;
    }
    .swiper-arrows {
        right: 20px;
        bottom: 36px;
    }
    .swiper-button {
        width: 56px;
        height: 56px;
    }
}

.b-slide-section__description {
    transition: all .2s;
    opacity: 0;
    display: none;
}