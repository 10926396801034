.b-advantages {}

.b-advantages__wrapper {
    padding: 120px;
    background-color: #fafafa;
    border-radius: var(--radius);
}

.b-advantages__title {
    text-align: center !important;
    margin-bottom: 120px;

    @include xl() {
        margin-bottom: 100px;
    }

    @include lg() {
        margin-bottom: 80px;
    }

    @include md() {
        margin-bottom: 60px;
    }
}

.b-advantages__list {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    flex-wrap: wrap;
    row-gap: 40px;

    @include child-counter('> li', '• 0', '\a') {
        color: #fff;
        background-color: var(--accent-color);
        display: inline-block;
        border-radius: 80px;
        font-size: 18px;
        padding: 5px 10px;
        vertical-align: super;
    }
}

.b-advantages__item {
    max-width: 400px;
    flex: 1 1 30%;
}

.b-advantages__item-title {
    display: inline-block;
    margin-left: 20px;
    @include adaptive-font(28, 22);
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 40px;
}

.b-advantages__item-text {
    font-weight: 400;
}

@media(max-width: 1028px) {
    .b-advantages__list>li:before {
        font-size: 12px;
    }

    .b-advantages__item-title {
        margin-left: 5px;
        margin-bottom: 25px;
    }

    .b-advantages__item {
        min-width: 235px;
    }

    .b-advantages__list {
        justify-content: center;
        row-gap: 40px;
    }

    .b-advantages__wrapper {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

@include xs() {
    .b-advantages__item {
        margin-right: auto;
    }
}

@media (max-width: 460px) {

    .b-advantages__wrapper {
        width: 100vw;
        padding-right: 25px;
        padding-left: 25px;
        padding-top: 40px;
        padding-bottom: 40px;
        border-radius: 0;
    }
}

@media(max-width: 910px) {
    .b-advantages__list {
        flex-direction: column;
    }

    .b-advantages__item {
        flex: 1 1 100%;
        max-width: 100%;
    }
}