.b-details-course {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    column-gap: 60px;

    @include sm(){
        flex-direction: column;
    }
}

.b-details-course__col {
    max-width: 760px;
}

.b-details-course__details {
    display: flex;
    align-items: flex-start;
    column-gap: 40px;

    & .b-details__value-position {
        margin-bottom: 0 !important;
    }

    & .b-details__title-position {
        margin-bottom: 5px;
        // width: 50%;
    }

    @include lg() {
        flex-direction: column;
    }

    @include sm(){
        justify-content: left;
        flex-direction: row;
    }

    @include xs(){
        flex-direction: column;
    }
}

@include xs(){
    .b-details-course__details:last-child {
        margin-bottom: 20px;
    }
}

.b-details-course__details .b-details__position {

    @include lg() {
        &:not(:last-child) {
            margin-bottom: 25px;
        }
    }

    &:before {
        background-color: #E8F6FC;
    }
}

.b-details-course__col_left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}

.b-title {}

.b-title_lg {}

.b-details {}


.b-details__position {}

.b-details__title-position {}

.b-details__value-position {}

.b-details-course__col_right {}


.b-details-course__text {
    font-weight: 400;
    @include adaptive-font(24, 18);

    @include lg() {
        font-size: 20px;
    }
}

.b-details-course__text:not(:last-child) {
    margin-bottom: 12px;
}

.b-details__value-position_time {
    white-space: nowrap;
}

@media(max-width: 1200px) {
    .b-first-screen_singl-page {

        & .b-description-course {
            flex-direction: column-reverse;
        }

        & .b-description-course__col_right {
            margin-left: 44px;
            margin-bottom: 40px;
        }

        & .b-description-course {
            width: fit-content;
        }

        & .b-first-screen__inner {
            max-width: max-content;
        }

        & .b-first-screen__wrapper {
            justify-content: space-evenly;
        }

        @include md() {
            & .b-first-screen__wrapper {
                justify-content: space-between;
            }

            & .b-first-screen__inner {
                flex-shrink: 1;
            }

        }
    }
    .b-first-screen_singl-page .b-first-screen__inner {
        @media(max-width: 1200px) {
            padding-bottom: 100px;
        }

        @include xs(){
            padding-bottom: 60px;
        }
    }
}

.b-details-course .b-details__position {
    width: 50%;
}