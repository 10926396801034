.b-footer {}

.b-footer__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    column-gap: 60px;
}

.b-footer__col {
    display: flex;
    flex-direction: column;
}

.b-footer__logo {
    max-width: 480px;
}

.b-contacts {
    display: flex;
    flex-direction: column;
}

.b-contacts__tell {
    display: flex;
    flex-direction: column;
}

.b-contacts__operator {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 5px;
    opacity: .6;
}

.b-contacts__link {
    @include adaptive-font(24, 18);
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 20px;
    transition: all .3s;
    white-space: nowrap;

    &:hover {
        color: var(--accent-color);
    }

}

.b-addresses {}

.b-addresses__address {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
}

.b-addresses__address-link {
    @include adaptive-font(24, 18);
    line-height: 1.2;
    font-weight: 600;
    transition: all .3s;
}

.b-addresses__mail {
    transition: all .3s;
    margin-bottom: 40px;

    &:hover {
        color: var(--accent-color);
    }
}

.b-addresses__mail-link {
    @include adaptive-font(24, 18);
    line-height: 1.2;
    font-weight: 600;
}

.b-socials {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.b-addresses__clue {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 5px;
    opacity: 0.6;
    font-size: 14px;
    font-weight: 500;
    opacity: .6;
    position: relative;
    display: inline-block;
    text-decoration: underline;
    transition: all .3s;

    &:hover {
        opacity: 1;
        color: var(--accent-color);
    }
}

.b-footer__col_address {
    max-width: 400px;
}
.b-socials__link {
    border: 4px solid var(--accent-color);

}
.b-socials__link_facebook {
    width: 60px;
    height: 60px;
    display: block;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.b-socials__link_instagram {
    width: 60px;
    height: 60px;
    display: block;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.b-socials__list-item {
    display: flex;
    align-items: center;
    column-gap: 20px;
    cursor: pointer;


}

.b-socials__link {
    &:hover svg path {
        transition: fill .3s;
        fill: var(--accent-color);
    }
}

.b-footer__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;

    & p,
    & a {
        font-size: 14px;
        line-height: 30px;
        opacity: .6;
    }

    & a {
        position: relative;

        @media(max-width: 480px) {
            margin-bottom: 15px;
        }

        &:hover span:last-child {
            transition: color .4s linear .6s;
            color: #f5ca09;
        }

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            width: 0;
            left: 50%;
            bottom: 0;
            height: 1px;
            background-color: #f5ca09;
            transition: all .4s ease-in .1s;
        }
    }

    &:hover a:after {
        width: 110%;
        left: -15px;
    }

    @media(max-width: 480px) {
        flex-direction: column;
    }
}

@include md() {
    .b-page-footer {
        padding-left: 25px;
        padding-right: 25px;
    }

    .b-socials__link {
        width: 50px;
        height: 50px;
    }

    .b-footer__top {
        flex-wrap: wrap;
    }

    .b-footer__col:first-child {
        flex: 1 1 100%;
    }

    .b-footer__col {
        flex: 1 1 40%;
    }

    .b-footer__logo {
        max-width: 340px;
        margin-bottom: 60px;
        display: block;
        // margin-left: auto;
        // margin-right: auto;
    }
}

@media(max-width: 622px) {
    .b-footer__top {
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .b-contacts {
        margin-bottom: 20px;
    }

    .b-addresses__mail {
        margin-bottom: 20px;
    }

    .b-footer__logo {
        max-width: 280px;
    }

    .b-socials {
        margin-left: auto;
        margin-right: auto;
    }
}