.b-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.b-header__menu {
    @media (max-width: 1000px) {
    }
}

.b-header__select-phone {
    margin-left: 52px;
    @include sm {
        display: none;
    }
}

.b-logo {}

.b-header__logo {
    min-width: 230px;
    max-width: 312px;
    max-height: 74px;
    object-fit: content;
    flex-shrink: 2;
    margin-right: 20px;
}

.b-logo__image {}

.b-nav {
    width: 100%;
    & > ul {
        display: flex;
        justify-content: end;
        @media (max-width: 1000px) {
            justify-content: center;
        }
        @include sm() {
            display: none;
        }
        & > li {
            &:not(:last-child) {
                margin-right: 30px;
                @include lg() {
                    margin-right: 15px;
                }
                @include sm() {
                    margin-right: 0;
                    margin-bottom: 5px;
                }
            }
            & > a {
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
                transition: color .2s;
                white-space: nowrap;
                &:hover {
                    color: var(--accent-color);
                }
                @include sm() {
                    font-size: 21px;
                }
            }
        }
    }
    .b-menu__item_phone {
        display: none;
        @include sm() {
            display: block;
        }
    }
    .b-menu__item_mt50 {
        margin-top: 50px;
        @media (max-height: 700px) {
            margin-top: 15px;
        }
        @media (max-height: 500px) {
            margin-top: 0;
        }
    }
}


.b-select-phone__call,
.b-select-phone__active-number {
    // font-size: 20px;
    font-weight: 600;

    @include sm() {
        font-size: 20px;
    }
}

.b-menu__link_disabled {
    opacity: 0.6;
    cursor: default;
    pointer-events: none;
}

.b-select-phone {
    position: relative;
}

.b-select-phone__dropdown {
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: -12px;
    width: 100%;
    height: 0;
    display: block;
    transform: translateY(100%);
    transition: all .4s;
    overflow: hidden;
    z-index: 999;
    display: flex;
    justify-content: center;

    // background-color: #fff;
    @include sm() {
        height: auto;
    }

    @include sm() {
        left: 0;
    }
}

.b-select-phone__btn,
.b-select-phone {
    transition: all .4s;
}

.b-select-phone {
    padding-right: 22px;

    .b-select-phone__dropdown {
        padding-left: 0;
        padding-right: 15px;
        background-color: #fff;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

.b-select-phone:hover .b-select-phone__dropdown {
    height: 300%;
}

.b-select-phone:hover .b-select-phone__btn {
    transform: rotate(180deg);
}


.b-select-phone__call {}

.b-select-phone__current {
    transition: color .2s;
    white-space: nowrap;

    &:hover {
        opacity: .75;
    }
}

.b-select-phone__number {
    padding: 10px 0 0;
}

.b-select-phone__number:hover {
    opacity: .75;
}

.b-select-phone__btn {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 2L6 6L2 2' stroke='%23141B1F' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: content;
    background-position: center;
    width: 10px;
    height: 10px;
    margin-left: 16px;
    position: relative;
    top: -3px;
}

.b-header__logo_mob {
    display: none;
    position: fixed;
    left: 10%;
    top: 10%;
}

//adaptive
@media(max-width: 1000px) {
    .b-header {
        flex-direction: column;
        min-width: 100%;
        // position: relative;
    }

    .b-header__logo {
        margin-right: 0;
        margin-bottom: 40px;
    }
}

@include sm() {
    .b-menu {
        flex-direction: column;
        align-items: center;
        max-width: 100vw;
        position: absolute;
        left: 100%;
        transition: all .4s;
        z-index: 9998;
        display: none;
        width: 100%;
        background-color: #fff;
        padding-bottom: 100px;
        overflow-y: scroll !important;
        height: calc(100vh - 114px);
        top: 114px;

        // justify-content: center;
        @media(max-width: 460px){
            top: 100px;
            height: calc(100vh - 100px);
        }
        @include mob() {
            height: calc(100vh - 97px);
            ;
            top: 97px;
        }

        &.active-menu {
            left: 0;
        }

        &.active-block {
            display: flex;
        }

    }

    .b-menu__item:first-child {
        margin-top: auto;
    }

    .b-menu__item:last-child {
        margin-bottom: auto;
    }

    .b-select-phone {
        margin-top: 100px;
    }

    .b-header__logo {
        margin-right: auto;
    }

    .b-menu__item {
        margin-right: 0 !important;
        margin-left: 0 !important;
        width: 100%;
    }

    .b-menu__link {
        font-size: 22px;
        width: 100%;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;

        @include mob() {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }


    .b-select-phone__current {
        padding-top: 30px;
    }

    .b-select-phone__btn {
        display: none;
    }

    .b-select-phone__active-number,
    .b-select-phone__number {
        display: block;
        font-weight: 600;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .b-select-phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    @include sm() {

        .b-select-phone__active-number,
        .b-select-phone__call {
            font-size: 22px !important;
            font-weight: 600 !important;
        }
    }

    .b-burger {
        position: absolute;
        z-index: 9999;
        right: 20px;
        top: 47px;
        width: 56px ;
        height: 40px;

        @include mob(){
            top: 36px !important;
        }
    }



    .b-burger__btn_open {
        width: 40px;
        height: 4px;
        background-color: var(--accent-color);
        position: absolute;
        border-radius: 40px;
        transform: scale(1);
        transition: transform .3s cubic-bezier(0, 1.64, 1, 1), opacity .4s;
        transform-origin: center;
        opacity: 1;

        &:after,
        &:before {
            content: '';
            position: absolute;
            background-color: var(--accent-color);
            border-radius: 40px;
            width: 40px;
            height: 4px;
            left: 0;
        }
    }

    .b-burger__btn_open {
        display: block;

        &:after {
            top: -10px;
        }

        &:before {
            top: 10px;
        }
    }

    .b-burger__btn_close {
        border-radius: 40px;
        width: 20px;
        height: 18px;
        position: relative;
        transform: scale(0);
        top: -15px;
        left: -1px;
        transition: transform .3s cubic-bezier(0, 1.64, 1, 1), opacity .4s;
        transform-origin: right;
        opacity: 0;

        & .b-burger_line {
            height: 4px;
            background-color: var(--second-color);
            width: 40px;
            position: absolute;
            left: 0;
            border-radius: 40px;

            &_first {
                top: 0;
                width: 40px;
                transform-origin: center;
                transform: rotate(45deg) translate(12px, 10px);
            }

            &_second {
                width: 40px;
                transform-origin: center;
                transform: rotate(-45deg) translate(0px, 2px);
                bottom: 0;
                width: 40px;
            }
        }
    }
}

.b-burger.active-burger {

    .b-burger__btn_open {
        transform: scale(0);
        opacity: 0;
    }

    .b-burger__btn_close {
        transform: scale(1);
        opacity: 1;
    }
}

@include mob() {
    .b-header__logo {
        max-width: 228px;
    }

    .b-burger {
        top: 44px;
    }
}

@include xs() {
    .b-header__logo {
        margin-bottom: 0;
    }
}

@media(max-width: 360px) {
    .b-header__logo {
        max-width: 200px;
    }
}