.b-test {}

.b-test__header {}


.b-test-header__text-result {
    line-height: 1.6;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 40px;

    @include xs() {
        font-size: 18px;
    }
}

.b-test-result {
    display: flex;
    justify-content: space-between;
    column-gap: 80px;

    @include md() {
        flex-direction: column;
    }
}

.b-test__result-content {}

.b-test-result__col {
    flex: 1 1 47%;
    max-width: 480px;

    @include md() {
        max-width: 100%;
        flex: 1 1 100%;

        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }
}

.b-test-result__message {}

.b-test-form {}

.b-test-result__form {}

.b-test-form__btn-wrapper {
    position: relative;
}

.b-test-form__input {
    min-width: 100%;
    display: block;
    @extend .b-form__input;

    @include mob() {
        max-height: 60px;
        font-size: 14px;
    }
}

.b-test-form-wrapper .wpcf7-form-control-wrap {
    margin-bottom: 20px;
    display: block;

    @include mob() {
        margin-bottom: 10px;
    }
}

.b-test-form__btn {
    width: 100%;
    text-align: left;

    &:after {
        display: block !important;
    }

    @include mob() {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

.b-test-result__agr {
    margin-top: 25px;
    max-width: 360px;
}

.b-btn {}

.b-btn_xl {}

.b-btn_arrow {}

.b-btn_secondary {}

.b-form {}

.b-form__agr {
    text-align: left;
    @include xs(){
        max-width: 100%;
    }
}

.b-form__agr-link {}

.b-test__header_resut {
    margin-bottom: 80px;

    @include md() {
        margin-bottom: 40px;
    }
}