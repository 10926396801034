.b-test-header__description-page {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 80px;
    font-weight: 400;

    @include md() {
        margin-bottom: 60px;
    }

    // @include xs() {
    //     margin-bottom: 40px;
    // }
}

.b-test-header__test-section-title {
    display: block;
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #47b4eb;
}

.b-test-header__title {
    text-align: left;
    margin-bottom: 40px;

    @include xs(){
        font-size: 26px;
        margin-bottom: 25px;
    }
}
