.b-ticket {
    background-color: #fff;
    border-radius: var(--radius);
    padding: get-vw(160px);

    @include xl(){
        padding: get-vw(100px);
    }

    @include xs(){
        padding: 25px;
        padding-top: 40px;
        padding-bottom: 40px;
        width: 100vw;
        border-radius: 0;
    }
}

.b-ticket__title {
    font-weight: 600;
    @include adaptive-font(36, 22);
    line-height: 1.4;
    margin-bottom: 80px;

    @include lg(){
        margin-bottom: 60px;
    }
    @include xs(){
        margin-bottom: 40px;
    }
}

.b-ticket__payments {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 40px;
    @include xs(){
    margin-bottom: 20px;
    }
}

.b-ticket__wrapper {
    display: flex;
}

.b-ticket__price {
    line-height: 1.2;
    font-weight: 700;
    @include adaptive-font(64,38);
    margin-bottom: 20px;
}
.b-ticket__sup {
    margin-left: 20px;
    @include adaptive-font(18, 12);
    font-weight: 500;
}

.b-ticket__price_current {
    color: #000;
}

.b-ticket__price_last {
    color: #D0D1D2;
    margin-bottom: 40px;
    text-decoration: line-through;
    text-decoration-color: #000;
    text-decoration-style: solid;
    -webkit-text-decoration-thickness: 2px;
    text-decoration-thickness: 3px;

    & + .b-ticket__sup {
        color: #D0D1D2;
    }
}

.b-ticket__description {
    @include adaptive-font(24, 18);
    line-height: 1.4;
    font-weight: 500;
}